import React, { Suspense } from "react";
import { createMedia } from "@artsy/fresnel";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Footer from "../components/Footer";

import DesktopContainer from "./DesktopContainer";
import MobileContainer from "./MobileContainer";

import Home from "../pages/home";
import Contact from "../pages/contact";
import Career from "../pages/career";
import Company from "../pages/company";
import MyProperties from "../pages/my-properties";
import PropertyListing from "../pages/propertyListing";
import Detail from "../pages/detail";
import DynamicPage from "../pages/dynamicPage";
import Blog from "../pages/blog";
import BlogPost from "../pages/blogPost";
import { pageEnum } from "../Common";

import createHistory from "history/createBrowserHistory";
import ReactGA from "react-ga";
import FlatMate from "../pages/flatmate";

const history = createHistory();
history.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

export const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
});

export const MENU_ITEMS = [
  { name: "Home", url: "/" },
  { name: "Long Lets", url: "/long-lets" },
  { name: "Commercials", url: "/commercials" },
  { name: "Short Lets", url: "/short-lets" },
  { name: "Residential Sales", url: "/residential-sales" },
  { name: "Commercial Sales", url: "/commercial-sales" },
  { name: "Find Flat Mate", url: "/flatmate" },
  { name: "Rooms", url: "/room" },
  { name: "Careers", url: "/career" },
  { name: "Blog", url: "/blog" },
  { name: "Contact Us", url: "/contact" },
  { name: "Add My Properties", url: "/my-properties" },

];

const ResponsiveContainer = ({ children }) => (
  <MediaContextProvider>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </MediaContextProvider>
);

function Router() {
  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <BrowserRouter>
      <ResponsiveContainer>
        <div /* style={{ minHeight: '100vh' }} */>
          <Switch>
            <Route path="/career">
              <Career />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/company">
              <Company />
            </Route>
            <Route path="/flatmate">
              <FlatMate />
            </Route>
            
            <Route path="/my-properties">
              <MyProperties />
            </Route>

            {/* <Route path="/detail/:id/:type" children={<Detail />}></Route> */}
            <Route
              path="/long-lets"
              render={() => (
                <Suspense fallback={<div>loading...</div>}></Suspense>
              )}
            >
              <PropertyListing pageNumber={pageEnum.LongLets} />
            </Route>
            <Route
              path="/commercials"
              render={() => (
                <Suspense fallback={<div>loading...</div>}></Suspense>
              )}
            >
              <PropertyListing pageNumber={pageEnum.CommercialLets} />
            </Route>
            <Route
              path="/short-lets"
              render={() => (
                <Suspense fallback={<div>loading...</div>}></Suspense>
              )}
            >
              <PropertyListing pageNumber={pageEnum.ShortLets} />
            </Route>
            <Route
              path="/residential-sales"
              render={() => (
                <Suspense fallback={<div>loading...</div>}></Suspense>
              )}
            >
              <PropertyListing pageNumber={pageEnum.ResidentialSale} />
            </Route>

            <Route
              path="/commercial-sales"
              render={() => (
                <Suspense fallback={<div>loading...</div>}></Suspense>
              )}
            >
              <PropertyListing pageNumber={pageEnum.CommercialSale} />
            </Route>

            <Route
              path="/room"
              render={() => (
                <Suspense fallback={<div>loading...</div>}></Suspense>
              )}
            >
              <PropertyListing pageNumber={pageEnum.Room} />
            </Route>
            <Route path="/blog/:slug" children={<BlogPost />} />
            <Route path="/blog" children={<Blog />} />
            <Route path="/:path" children={<DynamicPage />} />

            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
        <Footer />
      </ResponsiveContainer>
    </BrowserRouter>
  );
}

export default Router;
